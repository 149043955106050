import React, { useCallback, useEffect, useState } from "react";
import ColorSelection from "../ColorSelection";
import PreviewBox from "../SectionBox/PreviewBox";
import SectionBoxWrapper from "../SectionBox/SectionBoxWrapper";
import PreviewCheckout from "./PreviewCheckout";
import TextfieldDefault from "../../../../../components/textField/TextfieldDefault";
import TextfieldCommon from "../../../../../components/textField/TextfieldCommon";
import { Box, Typography } from "@material-ui/core";

export interface SectionBoxCheckoutProps {
  themeConfig: any;
  onSubmit: any;
}

/* Update checkout information and users can view all checkout information using card. */
const SectionBoxBorder: React.FunctionComponent<SectionBoxCheckoutProps> = ({
  themeConfig,
  onSubmit,
}) => {
  const [backgroundColor, setBackgroundColor] = useState("");
  const [bordersColor, setBordersColor] = useState("");
  const [borderRadius, setBorderRadius] = useState(0);
  const [borderThickness, setBorderThickness] = useState(0);

  /* Update states initial load and after update checkout information. */
  const setInitialData = useCallback(() => {
    if (backgroundColor) return;

    const { accentColor, boarderColor, cardBorderThickness, cardBorderRadius } = themeConfig;
    console.log("dssssssssssssssfgdfg", themeConfig)
    setBackgroundColor(accentColor);
    setBordersColor(boarderColor);
    setBorderThickness(cardBorderThickness);
    setBorderRadius(cardBorderRadius)
  }, [backgroundColor, themeConfig]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  /* Send an API call to update checkout information after click save button. */
  const handleSave = () => {
    onSubmit({
      accentColor: backgroundColor,
      boarderColor: bordersColor,
      cardBorderThickness: borderThickness,
      cardBorderRadius: borderRadius

    });
  };

  const handleChangeBorderRadius = (e: any) => {
    const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    setBorderRadius(value ? parseInt(value, 10) : 0); // Ensure it's an integer
  };

  const handleChangeBorderThickness = (e: any) => {
    const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric chara
    setBorderThickness(value ? parseInt(value, 10) : 0);
  };

  const {
    accentColor,
    boarderColor,
    titleTextColor,
    secondaryColor,
    secondaryTextColor,
    menuBackgroundColor,
  } = themeConfig;
  console.log("defgdfgdfgdfgfdg", borderThickness);
  return (
    <>
      <SectionBoxWrapper
        sectionTitle={"Border"}
        onSubmit={handleSave}
        onCancel={setInitialData}
        colorChangeSection={
          <>
            <Typography variant="body1" align="left">
              Border Radius
            </Typography>
            <TextfieldCommon
              id="borderRadius"
              name="borderRadius"
              type="text"
              label=""
              disabled={false}
              value={borderRadius}
              onChange={handleChangeBorderRadius}
            />
            <Typography
              variant="body1"
              align="left"
              style={{ marginTop: "12px" }}
            >
              Border Thickness
            </Typography>
            <TextfieldCommon
              style={{ marginBottom: "12px" }}
              id="borderThickness"
              name="borderThickness"
              type="text"
              label=""
              disabled={false}
              value={borderThickness}
              onChange={handleChangeBorderThickness}
            />
            <ColorSelection
              title={"Border Color"}
              colorCode={bordersColor}
              setColor={(color: any) => setBordersColor(color.hex)}
              setInitialData={() => setBordersColor(boarderColor)}
            />
          </>
        }
        previewSection={
          <PreviewBox bgColor={backgroundColor}>
            <Box
              style={{
                width: "100%",
                height: "40px",
                borderRadius: borderRadius,
                backgroundColor: "transparent",
                border: `${borderThickness}px solid ${bordersColor}`,
              }}
            ></Box>
          </PreviewBox>
        }
      />
    </>
  );
};

export default SectionBoxBorder;
