import React, { useState, useEffect, useCallback } from "react";
import { Grid, Hidden, useTheme } from "@material-ui/core";
import ThemeService from "../../services/ThemeService";
import { useRouteMatch } from "react-router";
import SectionBoxCategories from "./SectionBoxCategories";
import SectionBoxBasket from "./SectionBoxBasket";
import SectionBoxMenu from "./SectionBoxMenu";
import SectionBoxCheckout from "./SectionBoxCheckout";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { CustomTheme } from "../../../../types/customTheme";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMobile";
import SectionBoxBorder from "./SectionBoxBorder";

export interface ThemeCustomizationPageProps {}

/* Get all theme information and location information using API call at initial load. */
const ThemeCustomizationPage: React.FunctionComponent<
  ThemeCustomizationPageProps
> = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [themeConfig, setThemeConfig] = useState({});
  // const [locationData, setLocationData] = useState({ businessDisplayName: "" });

  let match: any = useRouteMatch();

  /* Get all theme information using API call and response data set to themeConfig state. */
  const fetchThemeConfig = useCallback(async () => {
    try {
      const res = await ThemeService.getThemeConfig(match.params.locationId);
      setThemeConfig(res.data.data);
    } catch (error) {
      setError("Unexpected error. Please check you connection and try again.");
    }
  }, [match.params.locationId]);

  /* Get all location information */
  // const fetchBasicInfo = async () => {
  //   try {
  //     const res = await getLocationBasicInfo(match.params.locationId);
  //     setLocationData(res.data.data);
  //   } catch (error) {
  //     setError(
  //       "Error fetching location basic info. Please check your internet connection.",
  //     );
  //   }
  // };

  // useEffect(()=>{
  //   fetchBasicInfo()
  // },[])

  useEffect(() => {
    document.title = "Eat Presto - Theme";
    fetchThemeConfig();
  }, [fetchThemeConfig, match.params.locationId]);

  /* Send an API call to update theme information after click save button. */
  const onSubmit = async (data: Object) => {
    const dataConfig = { ...themeConfig, ...data };

    try {
      const res = await ThemeService.updateThemeConfig(
        match.params.locationId,
        dataConfig,
      );
      setThemeConfig(res.data.data);
      setSuccess("Theme successfully updated.");
    } catch (error) {
      setError("Unexpected error. Please check you connection and try again.");
    }
  };

  const theme: CustomTheme = useTheme();

  return (
    <div>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity={"error"}
      />
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <Grid container spacing={2} style={{ marginTop: "18px" }}>
        <Grid item xs={12} sm={6} md={6}>
          <SectionBoxCategories themeConfig={themeConfig} onSubmit={onSubmit} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <SectionBoxBasket themeConfig={themeConfig} onSubmit={onSubmit} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <SectionBoxMenu themeConfig={themeConfig} onSubmit={onSubmit} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <SectionBoxCheckout themeConfig={themeConfig} onSubmit={onSubmit} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <SectionBoxBorder themeConfig={themeConfig} onSubmit={onSubmit} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ThemeCustomizationPage;
